html,
body {
  height: 100%;
  margin: 0;
}

/* nav bar styles */
.search-field {
  width: 20rem;
}

/* main styles, ensures <main> doesn't overlap nav */
main {
  margin-top: 5rem;
}

/* side panel styles */
.side-panel {
  .btn {
    margin-bottom: 1rem;
  }
}

.side-panel .card {
  margin-bottom: 1rem;
}

/* main content styles */
.content-controllers {
  margin-bottom: 1rem;
}

.content-cards {
  .col {
    margin-bottom: 1rem;
  }

  .card {
    height: 200px;
  }
}

/* create page styles */
.create {
  .card {
    max-height: 80vh;
    border: none;
  }

  .card-content {
    height: 100%;
    overflow-y: auto;
  }
}

.suggestions {
  margin-top: 10px;
  border-radius: 0.375rem;
  border: 1px solid #dee2e6;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.2);
  /* Shadow effect */

  .suggestion {
    padding: 0.375rem 0.75rem;
    border-bottom: 1px solid #dee2e6;
  }

  .suggestion:last-child {
    border-bottom: none;
  }

  .suggestion:hover {
    background-color: #dee2e6;
    transition: background-color 0.3s;
  }
}

/* popup styles */
.comment {
  font-size: 12px;
  color: #767676;
}

/* profile page styles */
.profile {

  .row {
    margin-bottom: 1rem;
  }

  .nav-tabs {
    margin-bottom: 1rem;
  }

  .nav-link {
    color: gray;
  }

  .nav-link.active {
    color: black;
  }
}

/* popup styles */
.popup {
  height: 100%;

  .row {
    height: 100%;

    .col-md-6 {
      height: 100%;
    }

    .column {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .comment-section {
        max-height: 55%;
      }
    }

    .column {
      display: flex;
      flex-direction: column;
      height: 100%;
      gap: 0.5rem;
    }

    .top-section {
      /* This is for the top content */
      flex: 0 0 auto;
      /* Do not grow or shrink */
    }

    .comment-section {
      /* This is for the comments */
      flex: 1;
      /* Grow to take available space */
      overflow-y: auto;
      /* Add scroll to comments if needed */
    }

    .bottom-section {
      /* This is for the bottom content */
      flex: 0 0 auto;
      /* Do not grow or shrink */
    }

    .scrollable {
      max-height: 100%;
      overflow-y: auto;
    }
  }
}

/* path detail styles */
.carousel {
  .fixed-size-image {
    width: 100%;
    height: 300px;
    object-fit: cover;
  }
}